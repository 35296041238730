import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, useMap, useMapEvents, Popup, Tooltip } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import axios from 'axios';

const fetchMarkersWithinBounds = (bounds) =>{

  const min_lat = bounds.southWest.lat;
  const max_lat = bounds.northEast.lat;
  const min_lng = bounds.southWest.lng;
  const max_lng = bounds.northEast.lat;
  
  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `https://api.stap.n2048.com/markers?_where[latitude_gt]${min_lat}&_where[latitude_lt]=${max_lat}&_where[longitude_gt]=${min_lng}&_where[longitude_lt]=${max_lng}`,
    headers: { }
  };

  return axios.request(config)
  .then((response) => {
    let cameras = response.data;
    return cameras;
  })
  .catch((error) => {
    console.log(error);
  });

}

const MapComponent = () => {
  const [center, setCenter] = useState(null);
  const [bounds, setBounds] = useState(null);
  
  const [markers, setMarkers] = useState(null)


  const map = useMap(); // Access the Leaflet map instance
  
  useEffect(() => {
    // Function to get and set center and bounds whenever the map moves
    const updateMapInfo = () => {
      const mapCenter = map.getCenter();
      setCenter({
        lat: mapCenter.lat,
        lng: mapCenter.lng,
        zoom: map.getZoom()
      });

      const mapBounds = map.getBounds();
      setBounds({
        northEast: mapBounds.getNorthEast(),
        southWest: mapBounds.getSouthWest(),
      });
    };

    // Call updateMapInfo on map moveend or zoomend
    map.on('moveend', updateMapInfo);
    map.on('zoomend', updateMapInfo);

    // Initial map info load
    updateMapInfo();

    // Cleanup event listeners on unmount
    return () => {
      map.off('moveend', updateMapInfo);
      map.off('zoomend', updateMapInfo);
    };
  }, [map]);

  useEffect(() => {
    if (center && bounds) {
      console.log('Center:', center);
      console.log('Bounds:', bounds);


      // Make an API call using the center or bounds data to fetch markers
      // For example, call an API to get markers within the bounds:
      fetchMarkersWithinBounds(bounds).then(markers => {
        setMarkers(markers);
      });
      ;
    }
  }, [center, bounds]);

  return (markers === null || markers.length === 0) 
  ? null 
  : markers.map(marker => {
    const {id, comment, type, photo} = marker;
    const lat_lng = { 
      lat: marker.latitude, 
      lng: marker.longitude 
    };
    const url = 'https://api.stap.n2048.com';
    const thumbnail = `${url}${photo.formats.thumbnail.url}`;
    
    return (
    <Marker key={id} position={lat_lng} >

      <Popup>
        <p>{comment}</p>
        <img src={thumbnail} alt={comment}></img>
      </Popup>
      <Tooltip>{type}</Tooltip>
    </Marker>
  )}
  )
};

const LocationMarker = () => {
    const [position, setPosition] = useState(null)

    useEffect(() => {   
         map.locate()
      }, []);

    const map = useMapEvents({
    //   click() {
    //     map.locate()
         //   alert ("add camera");

    //   },

      locationfound(e) {
        setPosition(e.latlng)
        map.flyTo(e.latlng, map.getZoom())
      },
    })
  
    return position === null ? null : (
      <Marker position={position}>
      </Marker>
    )
  }

const InteractiveMap = () => {
  const [markers, setMarkers] = useState([]);  

  const AddMarkerOnClick = () => {
    useMapEvents({
      click(e) {
        const { lat, lng } = e.latlng;
        
        // on click add camera: 
        //setMarkers((current) => [...current, { lat, lng }]);
      },
    });
    return null;
  };


  return (
    <MapContainer 
    center={[40.651601518462535, 22.982711791992188]} 
    zoom={13} 
    style={{ height: '100vh', width: '100%' }}
    scrollWheelZoom={false}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution="&copy; OpenStreetMap contributors"
      />
      {markers.map((marker, index) => (
        <Marker key={index} position={[marker.lat, marker.lng]} />
      ))}
      <AddMarkerOnClick />
      <LocationMarker />
      <MapComponent />
    </MapContainer>
  );
};

export default InteractiveMap;
