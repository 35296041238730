import React, { useEffect } from 'react';
import InteractiveMap from './InteractiveMap';
import './App.css';




function App() {
  
  useEffect(() => {
    // Request permissions
    requestCameraAccess();
    requestLocationAccess();
  }, []);

  return (
    <div className="App">
      <video style={{ display: 'none' }} autoPlay></video> {/* Hidden video for camera access */}
      <InteractiveMap />
  </div>
  );
}

const requestCameraAccess = async () => {
  try {
    const constraints = {
      video: {
        facingMode: { ideal: "environment" }
      }
    };
    const stream = await navigator.mediaDevices.getUserMedia(constraints);
    const video = document.querySelector("video");
    if (video) {
      video.srcObject = stream;
    }
  } catch (error) {
    console.error("Error accessing the camera: ", error);
  }
};

const requestLocationAccess = () => {
  if ("geolocation" in navigator) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        console.log("Latitude: ", position.coords.latitude);
        console.log("Longitude: ", position.coords.longitude);
      },
      (error) => console.error("Error accessing location: ", error)
    );
  } else {
    console.error("Geolocation is not available.");
  }
};

export default App;